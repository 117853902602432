import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { makeStyles } from "@mui/styles";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setExamusDisabledByCode } from "store/slices/groups.js";
import { clearVoucherError, setVoucherCode } from "../../store/slices/payment";
import {
  fetchVouchers,
  validatePaymentVoucher
} from "../../store/slices/vouchers";
import {
  Grid,
  TextField,
  Typography,
  Paper,
  Box,
  Divider,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { CardMessage, PrevNextSection } from "components";
import { LINKS } from "../../constants/links";
import ExamVouchersSelect from "./ExamVouchersSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "80px",
    paddingBottom: "80px"
  },
  voucherGroupContainer: {
    padding: theme.spacing(5),
    minWidth: "30%",
    maxWidth: "40%"
  },
  voucherTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: "bold"
  },
  DividerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "50%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  voucherForm: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(6)
  },
  shopVoucher: {
    marginTop: theme.spacing(1.5)
  },
  shopVoucherLink: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
    fontWeight: "bold",
    "&:hover": {
      color: theme.palette.kpYellowColors.main,
      textDecoration: "underline"
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#ffff"
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: "100%"
  }
}));

const ExamsSelection = () => {
  const {
    root,
    voucherGroupContainer,
    voucherTitle,
    DividerContainer,
    DividerBar,
    voucherForm,
    backdrop
  } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ! vars
  const [voucherSelectValue, setVoucherSelectValue] = useState("");
  const voucherInputRef = useRef(null);

  // ! selectors
  const { voucherCode } = useSelector((state) => state).paymentSlice;
  const allExamVouchers = useSelector(
    (state) => state.vouchersData?.vouchers?.examVouchers || null
  );
  const examCode = useSelector(
    (state) => state.examsSlice?.exam?.displayCode || null
  );

  // ! memos
  const examVouchers = useMemo(() => {
    if (!allExamVouchers || !examCode) {
      return null;
    }

    return allExamVouchers.filter(
      (el) => el.examCode.toLowerCase() === examCode.toLowerCase()
    );
  }, [allExamVouchers, examCode]);

  // ! fetch vouchers
  useEffect(() => {
    dispatch(fetchVouchers());
  }, [dispatch]);

  // ! handlers
  const handleChooseVoucher = (e) => {
    voucherInputRef.current.focus();
    dispatch(setVoucherCode(e.target.value || ""));
    setVoucherSelectValue(e.target.value);
  };

  const { loading, hasErrors } = useSelector((state) => state).vouchersData
    .currentVoucher;

  const { hasErrors: sessionErrors, loading: loadingSession } = useSelector(
    (state) => state
  ).examsSessionSlice;
  const { group } = useSelector((state) => state).groupSlice;

  useEffect(() => {
    dispatch(setVoucherCode(""));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePrev = useCallback(() => {
    if (group.status === "active") {
      navigate(LINKS.examsPaymentMethod);
    }
    if (!group.status) {
      navigate(LINKS.examsSelectGroup);
    }
  }, [group.status]);

  const handleNext = useCallback(async () => {
    dispatch(validatePaymentVoucher()).then((res) => {
      if (res.payload?.examusDisabled) {
        dispatch(setExamusDisabledByCode(true));
      }

      if (!res.error) {
        navigate(LINKS.examsInfo);
      }
    });
  }, []);

  if (!examCode) {
    return (
      <Navigate to={LINKS.examsSelection}/>
    )
  }

  if (sessionErrors) {
    return <CardMessage message={sessionErrors} backTo/>;
  }

  return (
    <>
      <Backdrop className={backdrop} open={loading || loadingSession}>
        <CircularProgress color="inherit"/>
      </Backdrop>
      <Grid className={root} container justifyContent="center" alignItems="center">
        <Paper className={voucherGroupContainer}>
          <Typography
            className={voucherTitle}
            align="center"
            variant="h5"
            color="primary">
            Voucher Code
          </Typography>
          <Box className={DividerContainer}>
            <Divider className={DividerBar}/>
          </Box>
          <Grid item>
            <form
              className={voucherForm}
              noValidate
              autoComplete="off"
              onSubmit={(e) => e.preventDefault()}>
              <TextField
                id="outlined-error-helper-text"
                label="Enter code"
                error={hasErrors && voucherCode}
                autoFocus
                fullWidth
                value={voucherCode}
                onFocus={() => dispatch(clearVoucherError())}
                variant="outlined"
                ref={voucherInputRef}
                helperText={
                  (hasErrors === "Some of the request parts are invalid" &&
                    voucherCode &&
                    "Invalid voucher ID") ||
                  (hasErrors && voucherCode && hasErrors)
                }
                onChange={(e) => dispatch(setVoucherCode(e.target.value))}
              />
            </form>
            {/* <Typography
              className={shopVoucher}
              variant="subtitle2"
              color="textSecondary">
              Don't have a Voucher? {` `}
              <a
                className={shopVoucherLink}
                href={LINKS.shop}
                target="_blank"
                rel="noreferrer">
                SHOP
              </a>
            </Typography> */}
            {!!examVouchers?.length && (
              <ExamVouchersSelect
                examVouchers={examVouchers}
                handleChooseVoucher={handleChooseVoucher}
                voucherSelectValue={voucherSelectValue}
              />
            )}
            {/*<Grid item>
              {
                !!examVouchers?.length &&
                <FormControl variant="outlined" className={formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Or Choose Voucher</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={voucherSelectValue}
                    onChange={handleChooseVoucher}
                    label="Or Choose Voucher">
                    <MenuItem value={null}>
                      <em>None</em>
                    </MenuItem>
                    {
                      examVouchers.map((v) => {
                        return <MenuItem value={v.code}>{v.examCode} ({format(Date.parse(v.createDate), 'dd  MMMM yyyy')})</MenuItem>;
                      })
                    }
                  </Select>
                </FormControl>
              }
            </Grid>*/}
          </Grid>
          <PrevNextSection
            handlePrev={handlePrev}
            handleNext={handleNext}
            disabledPrev={loading || loadingSession}
            disabledNext={!voucherCode || loading || loadingSession}
          />
        </Paper>
      </Grid>
    </>
  );
};

export default ExamsSelection;
