import React, { useState, useCallback, useEffect } from "react";
import {
  checkTestingGroupCodeThunk,
  clearAllFormFields,
  clearError,
  getGroup,
  setExamusDisabledByCode
} from "store/slices/groups";
import { useSelector, useDispatch } from "react-redux";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Paper,
  Grid,
  TextField,
  Backdrop,
  Box,
  CircularProgress,
  Divider
} from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { LINKS } from "constants/links";
import { PrevNextSection } from "components";
import { format, add } from "date-fns";
import { unwrapResult } from "@reduxjs/toolkit";
import { paymentMethod } from "../../store/slices/payment";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "80px",
    paddingBottom: "80px",
    justifyContent: "center",
  },
  selectGroupContainer: {
    padding: theme.spacing(5),
    maxWidth: "60%"
  },
  selectGroupTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1)
  },
  DividerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "50%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  titleQuestion: {
    marginBottom: theme.spacing(5)
  },
  input: {
    width: "80%"
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 300
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  dontBelongText: {
    paddingTop: theme.spacing(2)
  },
  error: {
    color: "#f44336",
    margin: 0,
    fontWeight: "400",
    fontSize: "0.75rem",
    marginTop: "3px",
    lineHeight: "1.66",
    letterSpacing: "0.0333em",
    marginLeft: "14px"
  },
  expired: {
    color: "#f44336",
    fontWeight: "700",
    fontSize: "1rem",
    padding: "0.4rem"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#ffff"
  }
}));

export default function ExamSelectGroup() {
  const s = useStyles();
  const {
    root,
    selectGroupContainer,
    titleQuestion,
    backdrop,
    selectGroupTitle,
    DividerBar,
    DividerContainer
  } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allMyGroups, loading, group } = useSelector(
    (state) => state
  ).groupSlice;
  const errorMessage = useSelector((state) => state?.groupSlice?.errorMessage);
  const examCode = useSelector((state) => state?.examsSlice?.exam?.code);
  const { organization, loading: orgLoading } = useSelector(
    (state) => state.organization
  );

  const examAvailableInitial = {
    status: false,
    message: ""
  };

  const [radioType, setRadioType] = useState("");
  const [state, setState] = useState("");
  const [groupCode, setGroupCode] = useState("");
  const [examAvailable, setExamAvailable] = useState(examAvailableInitial);

  // useEffect(() => {
  //   dispatch(clearAllFormFields());
  //   if (!allMyGroups.length) dispatch(getMyGroups());
  // }, []); // eslint-disable-line

  const handleChangeRadio = useCallback((event) => {
    setRadioType(event.target.value);
    setExamAvailable(examAvailableInitial);
  }, []); // eslint-disable-line

  const handleChangeSelect = useCallback((event) => {
    dispatch(clearAllFormFields());
    dispatch(clearError());
    setState(event.target.value);
    setExamAvailable(examAvailableInitial);
  }, []); // eslint-disable-line

  const handleGroupCodeInput = useCallback(
    (event) => {
      const value = event.target.value;
      setGroupCode(value);
      setExamAvailable(examAvailableInitial);
    },
    [setGroupCode] // eslint-disable-line
  );

  const handlePrev = useCallback(() => {
    navigate(LINKS.examsConfirmPrivacy);
  }, []); // eslint-disable-line

  const handleNext = useCallback(async () => {
    dispatch(setExamusDisabledByCode(true));
    if (groupCode && radioType === "yes") {
      try {
        const res = await dispatch(checkTestingGroupCodeThunk({
          code: groupCode,
          examCode
        }));

        const unwrappedRes = unwrapResult(res);
        const { paymentType, proctoring } = unwrappedRes || {};
        if (paymentType && paymentType?.toLowerCase()?.includes("license")) {
          dispatch(paymentMethod("license"));
          dispatch(setExamusDisabledByCode(proctoring !== "online"));
          navigate(LINKS.examsInfo);
          return;
        }

        // TODO: redirect user to online proctoring when Ana tells we are ready
        if (proctoring) {
          console.log("proctoring: ", proctoring);
        }

        if (group.organizationId && organization && organization.id) {
          navigate(LINKS.examsPaymentMethod);
        } else {
          dispatch(paymentMethod("voucher"));
          navigate(LINKS.examsVoucher);
        }
        return;
      } catch (e) {
        console.log("e", e);
        return;
      }

      // dispatch(getGroup(textFieldVal))
    }
    if (state) {
      dispatch(getGroup(state));
    }
    if (radioType === "no") {
      if (group.organizationId && organization && organization.id) {
        navigate(LINKS.examsPaymentMethod);
      } else {
        dispatch(paymentMethod("voucher"));
        /**
         * Ana: "We select "Not part of a group" and we insert a voucher then we always have online proctoring"
         */
        dispatch(setExamusDisabledByCode(false));
        navigate(LINKS.examsVoucher);
      }
    }
  }, [groupCode, radioType, state, group.status]); // eslint-disable-line

  useEffect(() => {
    if (!dispatch) return;
    dispatch(setExamusDisabledByCode(false));
  }, [dispatch]);

  useEffect(() => {
    if (
      group.status === "active" &&
      radioType === "yes" &&
      examAvailable.status
    ) {
      navigate(LINKS.examsConfirmPrivacy);
    }
  }, [loading, group.status, radioType, examAvailable.status]); // eslint-disable-line

  const checkStartTime = useCallback(
    (startTime) => {
      if (new Date(startTime) - new Date() > 0) {
        const message = `Your exam will be available ${format(
          new Date(startTime),
          "yyyy-MM-dd"
        )} at ${format(new Date(startTime), "H:mm")}`;

        setExamAvailable({ status: false, message: message });
        return;
      }

      if (new Date() - add(new Date(startTime), { hours: 3 }) > 0) {
        const message = "Looks like your exam date has expired";
        setExamAvailable({ status: false, message: message });
        return;
      }
      if (new Date() - new Date(startTime) > 0) {
        setExamAvailable({ status: true, message: "" });
      }
    },
    [setExamAvailable]
  );

  useEffect(() => {
    if (group.startTime) checkStartTime(group.startTime);
  }, [group.startTime]); // eslint-disable-line

  const myGroups = allMyGroups
    ? allMyGroups.filter(({ status }) => status === "active")
    : [];

  if (!examCode) {
    return (
      <Navigate to={LINKS.examsSelection}/>
    )
  }

  return (
    <>
      <Backdrop className={backdrop} open={loading && radioType === "yes"}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid className={root} container justifyContent="center" alignItems="center">
        <Paper className={selectGroupContainer}>
          <Typography
            className={selectGroupTitle}
            align="center"
            variant="h5"
            color="primary"
          >
            Exam group
          </Typography>
          <Box className={DividerContainer}>
            <Divider className={DividerBar} />
          </Box>
          <Typography
            className={titleQuestion}
            color="textPrimary"
            variant="subtitle1"
            align="center"
          >
            Are you a part of an exam group? <br />
            Select <b>Yes</b> to associate your exam with an exam group or
            select <b>No</b> to continue.
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="confirm"
              name="confirm1"
              value={radioType}
              onChange={handleChangeRadio}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label={<Typography color="textPrimary">Yes</Typography>}
              />
              <FormControlLabel
                value="no"
                control={<Radio />}
                label={<Typography color="textPrimary">No</Typography>}
              />
            </RadioGroup>
          </FormControl>

          {radioType === "yes" && (
            <Grid item>
              <form
                noValidate
                autoComplete="off"
                className={`${s.formControl} ${s.justifyCenter}`}
              >
                <TextField
                  className={s.input}
                  id="outlined-error-helper-text"
                  label={"Enter Group Code"}
                  value={groupCode}
                  onChange={handleGroupCodeInput}
                  variant="outlined"
                  onFocus={() => {
                    dispatch(clearError());
                    dispatch(clearAllFormFields());
                  }}
                  error={!!errorMessage}
                  // disabled={!!state}
                  helperText={errorMessage && errorMessage}
                />
              </form>
            </Grid>
          )}

          {/*{radioType === 'yes' && (
            <Grid item>
              {myGroups.length > 0 && (
                <FormControl variant="filled" className={s.formControl}>
                  <InputLabel htmlFor="filled-age-native-simple">
                    Select Exam Group:
                  </InputLabel>
                  <Select
                    className={s.input}
                    error={hasErrors && state}
                    native
                    value={state}
                    disabled={!!!myGroups.length || !!textFieldVal}
                    onChange={handleChangeSelect}
                    inputProps={{
                      name: 'age',
                      id: 'filled-age-native-simple',
                    }}
                  >
                    <option aria-label="None" value="" />
                    {myGroups.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </Select>

                  {hasErrors && state && (
                    <p className={s.error}>This is not valid id</p>
                  )}
                  {group.status === 'inactive' && state && (
                    <p className={s.error}>This group is not active</p>
                  )}
                </FormControl>
              )}

              {!myGroups.length > 0 ? (
                <Grid item container className={s.dontBelongText}>
                  <Typography variant="subtitle1" color="secondary">
                    Looks like you account is not associated with any exam
                    group.
                  </Typography>
                </Grid>
              ) : (
                <form
                  noValidate
                  autoComplete="off"
                  className={s.formControl}
                >
                  <TextField
                    className={s.input}
                    id="outlined-error-helper-text"
                    label={'Enter Group ID'}
                    value={textFieldVal}
                    onChange={handleChangeInput}
                    variant="outlined"
                    onFocus={() => {
                      dispatch(clearError())
                      dispatch(clearAllFormFields())
                    }}
                    error={hasErrors && textFieldVal}
                    disabled={!!state}
                    helperText={
                      (hasErrors && textFieldVal && 'Invalid group ID') ||
                      (group.status === 'inactive' &&
                        textFieldVal &&
                        'This group is not active')
                    }
                  />
                </form>
              )}
            </Grid>
          )}*/}

          <Grid item container className={s.expired}>
            {" "}
            {examAvailable.message}
          </Grid>
          <PrevNextSection
            handlePrev={handlePrev}
            handleNext={handleNext}
            disabledPrev={loading}
            disabledNext={
              !radioType ||
              (radioType === "yes" && !state && !groupCode) ||
              loading
            }
          />
        </Paper>
      </Grid>
    </>
  );
}
