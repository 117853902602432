import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

export function htmlTerms() {
  return {
    __html: `<main id="main" class="">
	<div id="content" class="content-area page-wrapper" role="main">
	   <div class="row row-main">
		  <div class="large-12 col">
			 <div class="col-inner">
				<h1><strong>Privacy Policy</strong></h1>
				<p>This policy describes how Knowledge Pillars Education Inc. (“Knowledge Pillars”) collects, uses, and shares personal information. Please note: This policy does not cover the Knowledge Pillars store. You can jump to topics by going to the headings below:</p>
				<ul>
				   <li><a href="#_Types_of_Information">Types of Information We Collect.</a></li>
				   <li><a href="#_Use_and_Processing">Use and Processing of Information</a>.</li>
				   <li><a href="#_Sharing_of_Information.">Sharing of Information.</a></li>
				   <li><a href="#_Online_Purchases.">Online Purchases.</a></li>
				   <li><a href="#_Your_Choices.">Your Choices.</a></li>
				   <li><a href="#_How_We_Protect">How We Protect Personal Information</a>.</li>
				   <li><a href="#_Services_to_Children.">Services to Children.</a></li>
				   <li><a href="#_Miscellaneous">Miscellaneous</a>.</li>
				   <li><a href="#_Contact_Information.">Contact Information</a>.</li>
				</ul>
				<h1><a id="_Types_of_Information"></a>Types of Information We Collect.</h1>
				<p>The following provides examples of the type of information that we collect from you and how we use that information.</p>
				<table width="100%">
				   <tbody>
					  <tr>
						 <td width="19%">
							<p><strong>Context</strong></p>
						 </td>
						 <td width="40%">
							<p><strong>Types of Data</strong></p>
						 </td>
						 <td width="40%">
							<p><strong>Primary Purpose for Collection and Use of Data</strong></p>
						 </td>
					  </tr>
					  <tr>
						 <td width="19%">
							<p>Account Registration</p>
						 </td>
						 <td width="40%">
							<p>We collect your name, contact information, and professional information such as your company or industry when you create an account. We also collect information relating to the actions that you perform while logged into your account.</p>
						 </td>
						 <td width="40%">
							<p>We have a legitimate interest in providing account related functionalities to our users. Accounts can be used to save your preferences and transaction history.</p>
						 </td>
					  </tr>
					  <tr>
						 <td width="19%">
							<p>Cookies and first party tracking</p>
						 </td>
						 <td width="40%">
							<p>We use cookies. “Cookies” are small pieces of information that a website sends to a computer’s hard drive while a web site is viewed.</p>
						 </td>
						 <td width="40%">
							<p>We have a legitimate interest in making our website operate efficiently.</p>
						 </td>
					  </tr>
					  <tr>
						 <td width="19%">
							<p>Cookies and Third-Party Tracking</p>
						 </td>
						 <td width="40%">
							<p>We participate in behavior-based advertising, this means that a third party uses technology (e.g., a cookie) to collect information about your use of our website so that they can provide advertising about products and services tailored to your interests on our website, or on other websites.</p>
						 </td>
						 <td width="40%">
							<p>We have a legitimate interest in engaging in behavior-based advertising and capturing website analytics.</p>
						 </td>
					  </tr>
					  <tr>
						 <td width="19%">
							<p>Demographic Information</p>
						 </td>
						 <td width="40%">
							<p>We collect personal information, such as your age, gender, or location.</p>
						 </td>
						 <td width="40%">
							<p>We have a legitimate interest in understanding our users and providing tailored services.</p>
						 </td>
					  </tr>
					  <tr>
						 <td width="19%">
							<p>Email Interconnectivity</p>
						 </td>
						 <td width="40%">
							<p>If you receive email from us, we use certain tools to capture data related to when you open our message, click on any links or banners it contains and make purchases.</p>
						 </td>
						 <td width="40%">
							<p>We have a legitimate interest in understanding how you interact with our communications to you.</p>
						 </td>
					  </tr>
					  <tr>
						 <td width="19%">
							<p>Feedback/Support</p>
						 </td>
						 <td width="40%">
							<p>If you provide us feedback or contact us for support, we will collect your name and e-mail address, as well as any other content that you send to us, in order to reply.</p>
						 </td>
						 <td width="40%">
							<p>We have a legitimate interest in receiving, and acting upon, your feedback or issues.</p>
						 </td>
					  </tr>
					  <tr>
						 <td width="19%">
							<p>Mailing List</p>
						 </td>
						 <td width="40%">
							<p>When you sign up for one of our mailing lists, we collect your email address or postal address.</p>
						 </td>
						 <td width="40%">
							<p>We share information about our products and services with individuals that consent to receive such information. We also have a legitimate interest in sharing information about our products or services.</p>
						 </td>
					  </tr>
					  <tr>
						 <td width="19%">
							<p>Mobile Devices</p>
						 </td>
						 <td width="40%">
							<p>We collect information from your mobile device such as unique identifying information broadcast from your device and location when visiting our website or using our application.</p>
						 </td>
						 <td width="40%">
							<p>We have a legitimate interest in identifying unique visitors, and in understanding how users interact with us on their mobile devices.</p>
						 </td>
					  </tr>
					  <tr>
						 <td width="19%">
							<p>Partner Promotion</p>
						 </td>
						 <td width="40%">
							<p>We collect information that you provide as part of a co-branded promotion with another company. We may also have service providers collect information on our or our partners’ behalf.</p>
						 </td>
						 <td width="40%">
							<p>We have a legitimate interest in fulfilling our promotions.</p>
						 </td>
					  </tr>
					  <tr>
						 <td width="19%">
							<p>Surveys</p>
						 </td>
						 <td width="40%">
							<p>When you participate in a survey, we collect information that you provide through the survey. If the survey is provided by a third-party service provider, the third party’s privacy policy applies to the collection, use, and disclosure of your information.</p>
						 </td>
						 <td width="40%">
							<p>We have a legitimate interest in understanding your opinions and collecting information relevant to our organization.</p>
						 </td>
					  </tr>
					  <tr>
						 <td width="19%">
							<p>Website interactions</p>
						 </td>
						 <td width="40%">
							<p>We use technology to monitor how you interact with our website. This may include which links you click on, or information that you type into our online forms. This may also include information about your device or browser.</p>
						 </td>
						 <td width="40%">
							<p>We have a legitimate interest in understanding how you interact with our website to better improve it, and to understand your preferences and interests in order to select offerings that you might find most useful. We also have a legitimate interest in detecting and preventing fraud or other security incidents.</p>
						 </td>
					  </tr>
					  <tr>
						 <td width="19%">
							<p>Web logs</p>
						 </td>
						 <td width="40%">
							<p>We collect information, including your browser type, operating system, Internet Protocol (IP) address (a number that is automatically assigned to a computer when the Internet is used), domain name, click-activity, referring website, and/or a date/time stamp for visitors.</p>
						 </td>
						 <td width="40%">
							<p>We have a legitimate interest in monitoring our networks and the visitors to our websites. Among other things, it helps us understand which of our services is the most popular.</p>
						 </td>
					  </tr>
				   </tbody>
				</table>
				<p>In addition to the information that we collect from you directly, we may also receive information about you from other sources, including third parties, our affiliates, or publicly available sources. For example, we may receive information on graduates from a university or an employer.</p>
				<h1><a id="_Use_and_Processing"></a>Use and Processing of Information.</h1>
				<p>In addition to the purposes and uses described above, we use information in the following ways:</p>
				<ul>
				   <li>To identify you when you visit our website.</li>
				   <li>To track your progress through our certification programs.</li>
				   <li>To verify your certifications when requested by you or a third party.</li>
				   <li>To provide training and services or to process returns.</li>
				   <li>To improve our services and product offerings.</li>
				   <li>To conduct analytics.</li>
				   <li>To respond to inquiries related to support, sales, or other requests.</li>
				   <li>To send marketing and promotional materials, including information relating to our products, services, sales, or promotions.</li>
				   <li>For internal administrative purposes, as well as to manage our relationships.</li>
				</ul>
				<p>Although the sections above describe our primary purpose in collecting your information, in many situations we have more than one purpose. For example, if you register an account, we may collect your information to perform our contract with you. But we also collect your information as we have a legitimate interest in maintaining your information after your membership concludes so that we can quickly and easily respond to any questions about your history with the organization. As a result, our collection and processing of your information is based in different contexts upon your consent, our need to perform a contract, our obligations under law, and/or our legitimate interest in maintaining our programs.</p>
				<h1><a id="_Sharing_of_Information."></a>Sharing of Information.</h1>
				<p>In addition to the specific situations discussed elsewhere in this policy, we disclose information in the following situations:</p>
				<p><strong>Affiliates and Acquisitions.</strong> We may share information with our corporate affiliates (e.g., parent company, sister companies, distributors, subsidiaries, joint ventures, or other companies under common control). If another company acquires, or plans to acquire, our company, business, or our assets, we will also share information with that company, including at the negotiation stage.</p>
				<p><strong>Other Disclosures with Your Consent.</strong> We may ask if you would like us to share your information with other unaffiliated third parties who are not described elsewhere in this policy.</p>
				<p><strong>Other Disclosures without Your Consent.</strong> We may disclose information in response to subpoenas, warrants, or court orders, or in connection with any legal process, or to comply with relevant laws. We may also share your information in order to establish or exercise our rights, to defend against a legal claim, to investigate, prevent, or take action regarding possible illegal activities, suspected fraud, safety of person or property, or a violation of our policies, or to comply with your request for the shipment of products to or the provision of services by a third party intermediary.</p>
				<p><strong>Public.</strong> Some of our websites may provide the opportunity to post comments, or reviews, in a public forum. If you decide to submit information on these pages, that information may be publicly available.</p>
				<p><strong>Partner Promotion.</strong> We may offer contests, sweepstakes, or other promotions with third party partners. If you decide to enter a contest, sweepstakes, or promotion that is sponsored by a third party partner the information that you provide will be shared with us and with them. Their use of your information is not governed by this privacy policy.</p>
				<p><strong>Service Providers.</strong> We may share your information with service providers. Among other things service providers may help us to administer our website, conduct surveys, provide technical support, process payments, and assist in the fulfillment of orders.</p>
				<h1><a id="_Online_Purchases."></a>Online Purchases.</h1>
				<p>Knowledge Pillars offers products, exams, assessments and services for purchase remotely and electronically. Payments are processed securely by our selected payment processors. Knowledge Pillars does not store any credit or debit card information. This information is securely provided by Users directly to our payment processors at the time of purchase. To ensure the safety of electronic purchases, Knowledge Pillars complies with PCI-DSS (Payment Card Industry – Data Security Standard).</p>
				<h1><a id="_Your_Choices."></a>Your Choices.</h1>
				<p>You can make the following choices regarding your personal information:</p>
				<p><strong>Access to Your Personal Information.</strong> You may request access to your personal information by contacting us at the address described below. If required by law, upon request, we will grant you reasonable access to the personal information that we have about you.</p>
				<p><strong>Changes to Your Personal Information. </strong>We rely on you to update and correct your personal information. Most of our websites allow you to modify or delete your account profile. Note that we may keep historical information in our backup files as permitted by law. If our website does not permit you to update or correct certain information, contact us at the address described below.</p>
				<p><strong>Deletion of Your Personal Information.</strong> Typically, we retain your personal information for the period necessary to fulfill the purposes outlined in this policy, unless a longer retention period is required or permitted by law. You may, however, request information about how long we keep a specific type of information, or request that we delete your personal information by contacting us at the address described below. If required by law we will grant a request to delete information, but you should note that in many situations we must keep your personal information to comply with our legal obligations, resolve disputes, enforce our agreements, or for another one of our business purposes.</p>
				<p><strong>Objection to Certain Processing.</strong> You may object to our use or disclosure of your personal information by contacting us at the address described below.</p>
				<p><strong>Online Tracking.</strong> We do not currently recognize automated browser signals regarding tracking mechanisms, which may include “Do Not Track” instructions.</p>
				<p><strong>Promotional Emails.</strong> You may choose to provide us with your email address for the purpose of allowing us to send free newsletters, surveys, offers, and other promotional materials to you, as well as targeted offers from third parties. You can stop receiving promotional emails by following the unsubscribe instructions in e-mails that you receive. If you decide not to receive promotional emails, we may still send you service-related communications.</p>
				<p><strong>Revocation of Consent.</strong> If you revoke your consent for the processing of personal information, then we may no longer be able to provide you services. In some cases, we may limit or deny your request to revoke consent if the law permits or requires us to do so, or if we are unable to adequately verify your identity. You may revoke consent to processing (where such processing is based upon consent) by contacting us at the address described below.</p>
				<h1><a id="_How_We_Protect"></a>How We Protect Personal Information.</h1>
				<p>No method of transmission over the Internet, or method of electronic storage, is fully secure. While we use reasonable efforts to protect your personal information from unauthorized access, use, or disclosure, we cannot guarantee the security of your personal information. In the event that we are required by law to inform you of a breach to your personal information we may notify you electronically, in writing, or by telephone, if permitted to do so by law.</p>
				<p>Our website may permit you to create an account. When you do you will be prompted to create a password. You are responsible for maintaining the confidentiality of your password, and you are responsible for any access to or use of your account by someone else that has obtained your password, whether or not such access or use has been authorized by you. You should notify us of any unauthorized use of your password or account.</p>
				<h1><a id="_Services_to_Children."></a>Services to Children.</h1>
				<p>Where the User of our services is a child below the age of 16 years, we will request consent for the collection and processing of Personal Data from a holder of parental responsibility over the child.</p>
				<h1><a id="_Miscellaneous"></a>Miscellaneous.</h1>
				<p>The following additional information relates to our privacy practices:</p>
				<p><strong>Transmission of Information to Other Countries.</strong> As a multi-national, we transmit information between and among our affiliates. As a result, your information may be processed in a foreign country where privacy laws may be less stringent than the laws in your country. Nonetheless, where possible we take steps to treat personal information using the same privacy principles that apply pursuant to the law of the country in which we first received your information. By submitting your personal information to us you agree to the transfer, storage and processing of your information in a country other than your country of residence including, but not necessarily limited to, the United States. We aim to comply with the data protection law in all countries where we do business, including the laws of the European Union. We transfer Personal Data across borders in accordance with applicable law. When we transfer or store Personal Data of EU residents outside the European Economic Area, we do so in compliance with the applicable EU laws on data protection (including by working with US partners who comply with the EU-US Privacy Shield).</p>
				<p>If you would like more information concerning our attempts to apply the privacy principles applicable in one jurisdiction to data when it goes to another jurisdiction you can contact us using the contact information below.</p>
				<p><strong>Third Party Applications/Websites.</strong> We have no control over the privacy practices of websites or applications that we do not own.</p>
				<p><strong>Changes to This Privacy Policy.</strong> We may change our privacy policy and practices over time. To the extent that our policy changes in a material way, the policy that was in place at the time that you submitted personal information to us will generally govern that information unless we receive your consent to the new privacy policy.</p>
				<h1><a id="_Contact_Information."></a>Contact Information.</h1>
				<p>If you have any questions, comments, or complaints concerning our privacy practices please contact us at the appropriate address below. We will attempt to respond to your requests and to provide you with additional privacy-related information.</p>
				<p><a href="mailto:privacyteam@knowledge-pillars.com">privacyteam@knowledge-pillars.com</a></p>
				<p>+1 (302) 760-9056</p>
				<p>If you are not satisfied with our response, and are in the European Union, you may have a right to lodge a complaint with your local supervisory authority. EU data subject may also inquire about our privacy practices by contacting us as set forth below:</p>
				<p>Knowledge Pillars Education Inc.<br>8, The Green, Suite B, Dover<br>DE19901 – Delaware, United States<br>13027609056<br><a href="mailto:privacyteam@knowledge-pillars.com">privacyteam@knowledge-pillars.com</a></p>
				<p>Last Revised: 17 October 2020<br>Effective Date: 17 October 2020</p>
			 </div>
		  </div>
	   </div>
	</div>
 </main>
 `
  };
}

const useStyles = makeStyles({
  root: {
    color: "#424242",
    padding: "1rem 0 2rem"
  }
});

const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid
          container
          item
          xs={10}
          md={7}
          className={classes.root}
          dangerouslySetInnerHTML={htmlTerms()}
        />
      </Grid>
    </>
  );
};

export default PrivacyPolicy;
