import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

export function htmlTerms() {
  return {
    __html: `<main id="main" class="">
	<div id="content" class="content-area page-wrapper" role="main">
	  <div class="row row-main">
		<div class="large-12 col">
		  <div class="col-inner">
			<h1>
			  <strong>Terms of Service</strong>
			</h1>
			<ol>
			  <li>
				<a href="#_1._Acceptance_and">Acceptance and Conditions of Use</a>
			  </li>
			  <li>
				<a href="#_2._Prohibited_Conduct">Prohibited Conduct</a>
			  </li>
			  <li>
				<a href="#_3._Confidentiality_of">
				  Confidentiality of Certification Process and Materials
				</a>
			  </li>
			  <li>
				<a href="#_4._Access_to">Access to Online Services</a>
			  </li>
			  <li>
				<a href="#_5._Intellectual_Property">
				  Intellectual Property Rights
				</a>
			  </li>
			  <li>
				<a href="#_6._Materials_Provided">Materials Provided “As Is”</a>
			  </li>
			  <li>
				<a href="#_7._Credit_Card">
				  Credit Card Payment, Cancellation, Rescheduling and Refund
				  Policy
				</a>
			  </li>
			  <li>
				<a href="#_8._Links_to">Links to Third Party Websites</a>
			  </li>
			  <li>
				<a href="#_9._Changes_to">Changes to Products and Services</a>
			  </li>
			  <li>
				<a href="#_10._Law">Law</a>
			  </li>
			  <li>
				<a href="#_11._Exclusions_and">
				  Exclusions and Limitations of Liability
				</a>
			  </li>
			  <li>
				<a href="#_12._Amendments_to">
				  Amendments to Terms and Conditions
				</a>
			  </li>
			  <li>
				<a href="#_13._Severability">Severability</a>
			  </li>
			</ol>
			<p>
			  These Terms of Service (“Terms”) apply to use of websites and
			  associated online services operated by Knowledge Pillars Education Inc.
			  (together “Knowledge Pillars” or “we”), including
			  www.Knowledge-Pillars.com. Additional terms may apply to specific
			  Knowledge Pillars examination, assessment and certification
			  services.
			</p>
			<h1>
			  <a id="_1._Acceptance_and"></a>1. Acceptance and Conditions of Use
			</h1>
			<p>
			  Registered users of Knowledge Pillars services are required to
			  accept these Terms. If you intend to use our Online Services as a
			  visitor, you must comply with these Terms (if you do not agree
			  please do not use the Online Services). Both registered users and
			  visitors of the Online Services are referred to in these Terms as
			  “Users”.
			</p>
			<p>
			  If you breach any of these Terms, your authorization to use the
			  Online Services is terminated and you must immediately destroy any
			  materials downloaded from the Online Services.
			</p>
			<h1>
			  <a id="_2._Prohibited_Conduct"></a>2. Prohibited Conduct
			</h1>
			<p>
			  Users shall not, directly or indirectly, perform any of the
			  following actions:
			</p>
			<ol>
			  <li>
				Transmit, install, upload or otherwise transfer any content,
				software, virus, advertisement, communication, or other item or
				process to the Online Services which in any way impairs their use,
				or adversely affects Knowledge Pillars’ computers, servers or
				databases.
			  </li>
			  <li>
				Copy, modify, reverse engineer, disassemble, redistribute,
				republish, alter, create derivative works from, assign, license,
				transfer or adapt any of the software, information, text,
				graphics, source code or HTML code, or other content available on
				the Online Services.
			  </li>
			  <li>
				Remove or modify any copyright, trademark, legal notices, or other
				proprietary notices from the content available via the Online
				Services.
			  </li>
			  <li>
				Breach or bypass the Online Services’ security mechanisms or
				attempt to do so – this includes accessing any data or server you
				are not authorized to access.
			  </li>
			  <li>
				Use any device (such as a “web crawler” or other automatic
				retrieval mechanism or other means) to harvest information about
				the Online Services, other Users or Knowledge Pillars.
			  </li>
			</ol>
			<h1>
			  <a id="_3._Confidentiality_of"></a>3. Confidentiality of
			  Certification Process and Materials
			</h1>
			<p>
			  The entire certification process is confidential. Examination,
			  assessment and certification services (“Certification Services”) are
			  made available to individuals solely for their own assessment. In
			  connection with Certification Services, you undertake the obligation
			  to comply with applicable{" "}
			  <a href="https://knowledge-pillars.com/exam-policies/">
				Exam Policies
			  </a>{" "}
			  which are made available upon registration. You are expressly
			  prohibited from disclosing, publishing, reproducing, or transmitting
			  any material related to the certification process, in whole or in
			  part, in any form or by any means including visual, aural, verbal,
			  written, electronic or mechanical, for any purpose, without the
			  prior express written permission of Knowledge Pillars.
			</p>
			<h1>
			  <a id="_4._Access_to"></a>4. Access to Online Services
			</h1>
			<p>
			  If you access the Online Services using a Username (e.g. Candidate
			  ID) and a Password (e.g. Examination PIN) received from us, you are
			  responsible for maintaining the confidentiality of your Username and
			  Password, and for restricting access to any computer while logged in
			  with your Username and Password. Your account is strictly personal
			  and should not be shared or transferred to anyone else. You agree to
			  accept responsibility for all activities that occur under your
			  Username and Password. Knowledge Pillars may terminate accounts at
			  its sole discretion for any violation of these requirements or of
			  Candidate Certification Guides.
			</p>
			<p>
			  Users are solely responsible for ensuring that they have sufficient
			  and compatible hardware, software, telecommunications equipment and
			  Internet service necessary for use of the Online Services. You will
			  be notified by email where any such hardware, software, equipment
			  and service is required. This information is also available in the
			  “Technical Requirements” section of the Knowledge Pillars
			  certifications pages.
			</p>
			<p>
			  Knowledge Pillars will make all commercially reasonable efforts to
			  provide uninterrupted access to the Online Services. However,
			  Knowledge Pillars does not warrant availability of the Online
			  Services. Unavailability of the Online Services may occur due to
			  factors outside of Knowledge Pillars’ control. The Online Services
			  may be unavailable due to periodic maintenance or related reasons,
			  and in such cases Knowledge Pillars will take all commercially
			  reasonable steps to restore access within a reasonable period. The
			  term “commercially reasonable” means reasonable efforts taken in
			  good faith without an undue or burdensome use or expenditure of
			  time, resources, personnel or money.
			</p>
			<h1>
			  <a id="_5._Intellectual_Property"></a>5. Intellectual Property
			  Rights
			</h1>
			<p>
			  This Online Services and all information they contain (“Materials”)
			  are proprietary to Knowledge Pillars Education Inc. and Test Owners, who
			  reserve all intellectual property rights in all materials included
			  in the Online Services. You should assume that everything you see,
			  read and hear on the Online Services (such as products, trademarks,
			  logos, service marks, images, video, audio, photographs,
			  illustrations, text, testimonials and other materials) is copyright
			  and/or trademark protected.
			</p>
			<p>
			  You may not sell, reproduce, distribute, modify, display, prepare
			  derivative works of, re-post or otherwise use, store or distribute
			  any Materials without the written consent of Knowledge Pillars.
			  Knowledge Pillars reserves the right to take legal action in case of
			  any breach of these rights and restrictions.
			</p>
			<h1>
			  <a id="_6._Materials_Provided"></a>6. Materials Provided “As Is”
			</h1>
			<p>
			  The Materials have been prepared to provide information about
			  Knowledge Pillars and its products and services. Whilst Knowledge
			  Pillars has taken reasonable care in the preparation of the
			  Materials, the Online Services and Materials are provided on an “as
			  is” basis and without warranties of any kind with regard to the
			  accuracy, completeness or usefulness of any information. All
			  warranties whether statutory or implied (including fitness for
			  purpose, satisfactory quality and timely delivery) are hereby
			  expressly excluded to the fullest extent permitted by law.
			</p>
			<h1>
			  <a id="_7._Credit_Card"></a>7. Credit Card Payment, Cancellation,
			  Rescheduling and Refund Policy
			</h1>
			<p>
			  Knowledge Pillars offers products, exams and services for purchase
			  remotely and electronically. Credit card payments are processed
			  securely by our selected payment processors and are not accessed by
			  Knowledge Pillars. A charge from “www.knowledge-pillars.com” will
			  appear on your credit card statement for any purchase.
			</p>
			<p>
			  If you have made a purchase by mistake or have changed your mind,
			  you have the right to cancel your purchase by filing a written
			  request of cancellation within 14 days of your initial payment as
			  follows:
			</p>
			<p>
			  <strong>i.For Exams:</strong> If you have not booked a specific exam
			  date, cancellation can be made within 14 days from your initial
			  purchase. If you have booked a specific exam date, cancellation can
			  be made within 14 days from your initial purchase, provided that
			  this date is at least 14 days prior to the exam date.
			</p>
			<p>
			  <strong>ii.For Assessments:</strong> If you have not redeemed your
			  assessment key, cancellation can be made within 14 days from your
			  initial purchase.&nbsp;
			</p>
			<p>
			  All requests for cancellation must be emailed to{" "}
			  <a href="mailto:support@knowledge-pillars.com">
				support@knowledge-pillars.com
			  </a>
			  .&nbsp; In the event of an appropriate cancellation request,
			  Knowledge Pillars will provide you with a refund. Refunds will
			  always be made to the same credit card used for purchase.
			</p>
			<p>
			  If you have booked a specific exam date but would like to reschedule
			  your exam, you have the right to do so via your Knowledge Pillars
			  account. In this case, a reasonable fee may apply.
			</p>
			<h1>
			  <a id="_8._Links_to"></a>8. Links to Third Party Websites
			</h1>
			<p>
			  The Online Services may provide links to other websites maintained
			  by third parties, for your convenience only. Knowledge Pillars has
			  no responsibility for the content in any linked website. Knowledge
			  Pillars does not endorse or make any representations about them, or
			  any information, software or other products, services or materials
			  found there, or any results that may be obtained from using them. If
			  you decide to access any of the third-party websites linked from the
			  Online Services, you do this entirely at your own risk.
			</p>
			<h1>
			  <a id="_9._Changes_to"></a>9. Changes to Products and Services
			</h1>
			<p>
			  Products and services available via the Online Services are subject
			  to change and removal without notice. Knowledge Pillars will not be
			  responsible for any additional costs, delay or liability which you
			  or your company or agents may incur as a result of such changes.
			</p>
			<h1>
			  <a id="_10._Law"></a>10. Law
			</h1>
			<p>
			  These terms and conditions are governed by the laws of United States
			  of America, and any dispute relating to the Terms and conditions
			  shall be subject to the jurisdiction of the courts of USA. You agree
			  to indemnify Knowledge Pillars for any claims or damages resulting
			  from your breach of the Terms.
			</p>
			<h1>
			  <a id="_11._Exclusions_and"></a>11. Exclusions and Limitations of
			  Liability
			</h1>
			<p>
			  TO THE MAXIMUM EXTENT PERMITTED BY LAW, KNOWLEDGE PILLARS, ITS
			  SUPPLIERS, AND OTHER APPROVED THIRD PARTIES SHALL NOT BE LIABLE FOR
			  ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, THOSE
			  RESULTING FROM LOST PROFITS, LOST DATA, LOST TIME OR INTERRUPTION,
			  LOST BUSINESS OR ANTICIPATED SAVINGS) ARISING OUT OF THE USE,
			  INABILITY TO USE, OR THE RESULTS OF USE OF THIS SITE, ANY WEBSITES
			  LINKED TO THIS SITE, OR THE MATERIALS OR INFORMATION OR SERVICES
			  CONTAINED AT ANY OR ALL SUCH SITES, WHETHER BASED ON WARRANTY,
			  CONTRACT, TORT OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT
			  KNOWLEDGE PILLARS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
			  DAMAGES. IF YOUR USE OF THE MATERIALS, INFORMATION OR SERVICES FROM
			  THIS SITE RESULTS IN THE NEED FOR ADDITIONAL SUPPORT, SERVICING OR
			  REPAIR, YOU ASSUME ALL COSTS THEREOF. Nothing in this clause shall
			  exclude Knowledge Pillars’ liability for personal injury resulting
			  from negligence or for fraud.
			</p>
			<h1>
			  <a id="_12._Amendments_to"></a>12. Amendments to Terms and
			  Conditions
			</h1>
			<p>
			  We may revise these Terms to reflect changes in Knowledge Pillars
			  products or the law, or for other reasons, and the revised Terms
			  will be posted on this page. If we make any material change to the
			  Terms and you are a registered User, we will notify you by email. If
			  you use the Online Services after changes have been notified, it
			  will be deemed that you have accepted the amended Terms.
			</p>
			<h1>
			  <a id="_13._Severability"></a>13. Severability
			</h1>
			<p>
			  If a provision of these Terms is or becomes illegal, invalid or
			  unenforceable, that shall not affect the legality, validity or
			  enforceability of any other provision of the Terms.
			</p>
		  </div>
		</div>
	  </div>
	</div>
  </main>`
  };
}

const useStyles = makeStyles({
  root: {
    color: "#424242",
    padding: "1rem 0 2rem"
  }
});

const TermsOfService = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid
          container
          item
          xs={10}
          md={7}
          className={classes.root}
          dangerouslySetInnerHTML={htmlTerms()}
        />
      </Grid>
    </>
  );
};

export default TermsOfService;
