import {useTheme} from "@mui/styles"
import React, {useState, useEffect, useCallback, useMemo} from "react"
import {useSelector, useDispatch} from "react-redux"
import {useNavigate, useParams} from "react-router-dom"
import {
  LinearProgress,
  Grid,
  Backdrop,
  Typography,
  CircularProgress,
  Box, Button
} from "@mui/material"
import {clearActiveSession, logout} from "store/slices/user"
import {examsSessionWindow} from "./styles/styles"
import {WpWorkSpace} from "./wordpress_containers"
import {BackdropLoad} from "components"
import {
  ExamInstructions,
  WorkSpace,
  Description,
  ErrorMessage,
  SnackbarCredentials
} from "./components"
import {
  startExamSession,
  getSessionStatus,
  getSessionDetails,
  submitCurentTusk,
  addFlaggedTask,
  removeFlaggedTask,
  getSessionTasksStatus,
  getCurrentTaskData,
  forcedFinishSession,
  clearState,
  cleanStateToDefault,
  getSessionData,
  getTasksAnswers,
  sessionFailure
} from "store/slices/examsSession"
import {LINKS} from "constants/links"
import {examSessionStatuses} from "constants/constants"
import clsx from "clsx"
import CustomPdfModal from "components/CustomPdfModal/CustomPdfModal"
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"

import {
  TaskFooterSecondBar,
  TaskHeader,
  TaskFooterFirstBar,
  CustomDialogModal,
  PopUpTasks,
  PopUpFinish,
  PopUpFinishedExam,
  PopUpReminder,
  PopUpNotSubmit
  // ExamInstructions
} from "@knowledge-pillars-education-inc/kp-fe-lib"

export function WordpressExam() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const styles = examsSessionWindow()
  const theme = useTheme()

  const [open, setOpen] = useState(false)
  const [instructionWatched, setInstructionWatched] = useState(false)
  const [openHint, setOpenHint] = useState(false)
  const [iframeCredentials, setIframeCredentials] = useState({})
  const [popupType, setType] = useState(false)
  const [openDoc, setOpenDoc] = useState(false)
  const [timer, setTimer] = useState(1000)
  const [showLib, setShowLib] = useState(true)

  const {
    loading,
    hasErrors,
    sessionData,
    currentTask,
    sessionStatus,
    startLink,
    loadingMoveTo,
    redirect,

    taskNumber,

    sessionTasksStatus,
    currentSessionDetails,
    tasksAnswers,

    sessionFailureMessage
  } = useSelector((state) => state).examsSessionSlice
  const examusDisabledByCode = useSelector(
    (state) => state.groupSlice?.examusDisabledByCode
  )

  const {examName, userName, tasks, expirationDate} =
    currentSessionDetails || {}

  const wordpressFullHeight = clsx({
    [styles.wpWorkSpaceFullHeight]: sessionStatus === examSessionStatuses.Initiated, // if login
    [styles.wpWorkSpace]: sessionStatus !== examSessionStatuses.Initiated, // after login
    [styles.wpWorkSpaceHeight]: currentTask?.type === "multiple",
    [styles.wpWorkSpaceFullHeightStart]:
      currentTask?.type !== "multiple" && sessionStatus !== examSessionStatuses.Initiated
  })

  // const wordpressFooterSize = clsx({
  //   [styles.wpFooterMax]: !["multiple", "single"].includes(currentTask?.type),
  //   [styles.wpFooterMin]: ["multiple", "single"].includes(currentTask?.type)
  // });

  const footerHeight = useMemo(() => {
    let footerHeight = "113px"

    if (!["multiple", "single"].includes(currentTask?.type)) {
      footerHeight = "173px"
    }

    return footerHeight
  }, [currentTask?.type])

  const {hasErrors: paymentErrors} = useSelector(
    (state) => state
  ).paymentSlice

  const checkAllPassedQuestions = useMemo(() => {
    return (
      sessionTasksStatus &&
      !sessionTasksStatus?.find((task) => !task.flag && !task.answered)
    )
  }, [sessionTasksStatus])

  const isTaskFlagged = useMemo(() => {
    return sessionTasksStatus?.some(
      (e) => e.taskId === currentTask?.id && e.flag
    )
  }, [sessionTasksStatus, currentTask.id])

  const taskAnswer = useMemo(() => {
    return tasksAnswers?.find((task) => task.taskId === currentTask.id)
  }, [tasksAnswers, currentTask.id])

  const fetchData = async (sessionId) => {
    try {
      await dispatch(getSessionData(sessionId))
      await dispatch(getSessionDetails(sessionId))
      await dispatch(getCurrentTaskData())
      await dispatch(getTasksAnswers())
      await dispatch(getSessionTasksStatus())
    } catch (err) {
      dispatch(sessionFailure(err))
    }
  }

  useEffect(() => {
    if (currentSessionDetails._id) {
      dispatch(getSessionStatus(currentSessionDetails._id))
    }
  }, [currentSessionDetails._id]); // eslint-disable-line

  useEffect(() => {
    if (params.sessionId && !currentSessionDetails._id) {
      fetchData(params.sessionId)
    }
  }, [params.sessionId]); // eslint-disable-line


  const setCredentialsHint = useCallback(
    (sessionData) => {
      setIframeCredentials({
        name: sessionData?.instance?.username,
        password: sessionData?.instance?.password
      })
    },
    [sessionData] // eslint-disable-line
  )

  useEffect(() => {
    if (startLink && redirect && !examusDisabledByCode && !params.sessionId) {
      window.location.replace(startLink)
    }
  }, [startLink, redirect, examusDisabledByCode, params])

  useEffect(() => {
    let interval
    if (currentSessionDetails._id && sessionStatus && ![examSessionStatuses.Initiating, examSessionStatuses.Initiated, examSessionStatuses.InProgress].includes(sessionStatus)) {
      navigate("/")

      return
    }

    if (currentSessionDetails._id && sessionStatus === examSessionStatuses.Initiating) {
      interval = setInterval(async () => {
        dispatch(getSessionStatus(currentSessionDetails._id))
      }, 3000)
    }
    if (currentSessionDetails._id && sessionStatus === examSessionStatuses.Initiated) {
      clearInterval(interval)
      dispatch(getSessionDetails(params.sessionId))
    }
    return () => clearInterval(interval)
  }, [sessionStatus, currentSessionDetails._id]); // eslint-disable-line

  useEffect(() => {
    // if (currentSessionDetails._id && examusDisabledByCode && tasks[0]) {
    //   dispatch(getCurrentTaskData(tasks[0].taskId));
    // }
    setCredentialsHint(sessionData)
  }, [sessionData, examusDisabledByCode]); // eslint-disable-line

  useEffect(() => {
    if (timer < 300001 && timer > 299000) {
      setType("ExamReminder")
    }
    if (timer < 100 && popupType !== "scored") {
      setOpen(true)
      setType("finished-exem")
    }
  }, [timer]); // eslint-disable-line

  // useEffect(() => {
  //   if (sessionStatus === examSessionStatuses.Initiated) {
  //     setInstructionWatched(true);
  //   }
  // }, [sessionStatus]);

  const handleClose = async (closeType) => {
    if (closeType === "confirm") {
      dispatch(forcedFinishSession()).finally(() => {
        localStorage.removeItem("taskId")
        window.setTimeout(() => {
          dispatch(clearActiveSession())
          dispatch(clearState())
          dispatch(logout())
          navigate("/", {replace: true})
        }, 3000)
      })
      setType("scored")
      setOpen(true)
    }
    if (closeType === "hint") {
      setOpenHint(false)
    }
    if (closeType === "continue") {
      setOpen(false)
      setType(false)
      return
    }
    if (closeType === "review-tasks") {
      setType("tasks")
      setOpen(false)

      return
    }
    if (closeType === "exit") {
      dispatch(clearActiveSession())
      navigate("/")
      dispatch(cleanStateToDefault())
      return
    }
    if (closeType === "close") {
      setType("")
      return
    }
    if (closeType === "nextTask") {
      skipTask()
      setType("")
      return
    }
    if (closeType === "repeatQuestion") {
      setType("")
      return
    }
  }

  const handleStartSession = useCallback(() => {
    if (
      currentSessionDetails._id
      // &&
      // !params.sessionId &&
      // !examusDisabledByCode
    ) {
      dispatch(startExamSession(currentSessionDetails._id)).then(() => {
        navigate(
          `${LINKS.examWindow}/${params.examCode}/${currentSessionDetails._id}`
        )
        fetchData(params.sessionId).then(() => {
          setOpenHint(true)
        })
      })
    }
  }, [currentSessionDetails._id, LINKS, params, examusDisabledByCode]); // eslint-disable-line

  const submitInstructionWatched = useCallback(() => {
    setInstructionWatched(true)
    handleStartSession()
    // setOpenHint(true);
  }, [setInstructionWatched])

  // const submitInstructionWatched = () => {
  //   setInstructionWatched(false);
  //   if (sessionStatus === "initiated") {
  //     setOpenHint(true);
  //   }
  // };

  const ModalTitle = useMemo(() => {
    switch (popupType) {
      case "finishExamFirst":
      case "finishExamEnd":
        return "Finish exam"
      case "finished-exem":
        return "Exam finished"
      case "ExamReminder":
        return "Reminder"
      case "tasks":
        return "Review All Tasks"
      case "notSubmittedTask":
        return "Action required"
      default:
        return null
    }
  }, [popupType])

  const handleFlagClickFooter = () => async () => {
    if (isTaskFlagged) {
      await dispatch(
        removeFlaggedTask(currentSessionDetails._id, currentTask.id)
      )
    } else {
      await dispatch(addFlaggedTask(currentSessionDetails._id, currentTask.id))
    }
    await dispatch(getSessionTasksStatus())
  }

  const submitTask = () => {
    dispatch(submitCurentTusk()).then((res) => {
      if (res) {
        if (taskNumber === tasks?.length) {
          setType("tasks")
        } else {
          skipTask()
        }
      }
    })
  }

  const skipTask = () => {
    if (taskNumber < tasks?.length) {
      let taskId = tasks[taskNumber].taskId
      dispatch(getCurrentTaskData(taskId))
    }
  }

  const onPrevClick = () => {
    if (taskNumber > 1) {
      let taskId = tasks[taskNumber - 2].taskId
      dispatch(getCurrentTaskData(taskId))
    }
  }

  const goThisTask = async (taskId) => {
    await dispatch(getCurrentTaskData(taskId))
    setType(null)
  }

  const renderHelper = () => {
    switch (popupType) {
      case "tasks":
        return (
          <PopUpTasks handleClick={goThisTask} tasks={sessionTasksStatus} />
        )
      case "finishExamFirst":
      case "finishExamEnd":
        return <PopUpFinish handleClose={handleClose} popupType={popupType} />
      case "finished-exem":
        return <PopUpFinishedExam handleClose={handleClose} />
      case "ExamReminder":
        return <PopUpReminder handleClose={handleClose} />
      case "notSubmittedTask":
        return <PopUpNotSubmit handleClose={handleClose} />
      case "scored":
        return (
          <Backdrop className={styles.backdrop} open={true}>
            <Typography
              className={styles.loadingMessage}
              variant="h6"
              align="center"
            >
              Thank you for taking the exam. We will contact you in the next
              24-48h with your results. <br />
              <b>
                We are saving your exam information. <br /> Please do not close
                or leave this tab and we will redirect you to the main page in
                about 1 minute.
              </b>
            </Typography>
            <CircularProgress className={styles.progressBar} color="inherit" />
          </Backdrop>
        )
      default:
        return null
    }
  }

  if (!params.sessionId && !examusDisabledByCode || (sessionStatus === examSessionStatuses.Initiated && instructionWatched)) {
    return <BackdropLoad open={true} />
  }

  // if (loading || loadingMoveTo) {
  //   if (!examusDisabledByCode) return <BackdropLoad open={true} />;
  // }

  if (sessionStatus === examSessionStatuses.Initiating) {
    return (
      <Backdrop className={styles.backdrop} open={true}>
        <Typography variant="h6" align="center">
          We are currently preparing your exam and it may take few minutes.{" "}
          <br />
          <b>Please don't refresh or close the tab.</b>
        </Typography>
        <CircularProgress className={styles.progressBar} color="inherit" />
      </Backdrop>
    )
  }

  if (sessionFailureMessage) {
    return <ErrorMessage message={sessionFailureMessage} />
  }

  if (hasErrors || paymentErrors) {
    if (!examusDisabledByCode) return <ErrorMessage />
  }

  return (
    <>
      <Grid container>
        {/* {instructionWatched && (
        <ExamInstructions
          handleClick={() => submitInstructionWatched()}
          btnText="Confirm"
        />
      )}
      <Grid container sx={{ display: instructionWatched ? "none" : "flex" }}> */}
        {sessionStatus !== examSessionStatuses.Initiated && (
          <Box
            sx={{
              position: "fixed",
              top: "0",
              zIndex: 2,
              overflow: "hidden",
              width: "100%",
              height: "auto",
              maxHeight: showLib ? "100px" : 0,
              transition: "all .35s ease-out",
              "& > div": {
                position: "relative"
              }
            }}
          >
            <TaskHeader
              studentName={userName}
              sessionId={currentSessionDetails?._id}
              examName={examName}
              deadline={expirationDate}
              setTimer={setTimer}
              checkAllPassedQuestions={checkAllPassedQuestions}
              loading={loading}
              setType={setType}
              logo="/icons/kp_logo.png"
            />
          </Box>
        )}
        <Grid
          item
          xs={12}
          className={wordpressFullHeight}
          style={{
            height: !showLib ? "100vh" : undefined,
            marginTop: !showLib ? 0 : undefined,
            marginBottom: !showLib ? 0 : undefined,
            transition: "all 0.35s ease-out"
          }}
        >
          {openDoc && (
            <div className={styles.pdfWrapper}>
              <CustomPdfModal open={openDoc} setOpen={setOpenDoc} />
            </div>
          )}
          <WorkSpace
            children={<WpWorkSpace />}
            open={open}
            popupType={popupType}
            taskAnswer={taskAnswer}
          />
          <Box
            position="absolute"
            right={20}
            bottom={15}
            zIndex={10}
            sx={{
              transition: ".2s",
              opacity: .5,
              "&:hover": {
                opacity: 1,
                transition: ".2s"
              }
            }}
          >
            <Button
              onClick={() => setShowLib(!showLib)}
              sx={{
                transition: ".2s",
                boxShadow: 1,
                minWidth: "auto",
                "&:hover": {
                  background: theme.palette.white.main,
                  transition: ".2s"
                }
              }}
            >
              {showLib ? (
                <OpenInFullIcon fontSize="small"/>
              ) : (
                <CloseFullscreenIcon fontSize="small"/>
              )}
            </Button>
          </Box>
        </Grid>

        {iframeCredentials.name && (
          <SnackbarCredentials
            open={openHint}
            handleClose={() => handleClose("hint")}
            username={iframeCredentials.name}
            password={iframeCredentials.password}
          />
        )}

        {/* {sessionStatus === examSessionStatuses.Initiated && !instructionWatched ? ( */}
        {sessionStatus === examSessionStatuses.Initiated && !instructionWatched ? (
          <ExamInstructions
            headerOff
            fullScreen
            handleClick={() => submitInstructionWatched()}
            // btnText={"Confirm"}
          />
        ) : (
          <Grid
            item
            xs={12}
            sx={{
              height: !showLib ? 0 : footerHeight,
              overflow: "hidden",
              transition: "all 0.35s ease-out"
            }}
            className={styles.wpFooter}
          >
            <Box className={styles.loadingBarContainer}>
              {loadingMoveTo || loading ? <LinearProgress /> : null}
            </Box>
            <TaskFooterFirstBar
              handleFlagClick={handleFlagClickFooter}
              currentTaskNumber={taskNumber}
              tasksAmount={tasks?.length}
              setOpenHint={setOpenHint}
              btnFlaggedDisable={loading}
              setOpenDoc={setOpenDoc}
              openDoc={openDoc}
              isTaskFlagged={isTaskFlagged}
              examCode={currentTask?.examCode}
              isWordpressExam={true}
              // setInstructions={() => setInstructionWatched(true)}
            />
            <Description />
            <TaskFooterSecondBar
              nextDisabled={loading}
              prevDisabled={taskNumber === 1 || loading}
              onNextClick={submitTask}
              onPrevClick={onPrevClick}
              onNextQuestion={skipTask}
              isTaskFlagged={isTaskFlagged}
              skipTaskShow={taskNumber !== tasks?.length}
            />
          </Grid>
        )}
      </Grid>

      {popupType && (
        <CustomDialogModal
          popupType={popupType}
          examName={examName}
          setType={setType}
          ModalTitle={ModalTitle}
        >
          {renderHelper()}
        </CustomDialogModal>
      )}
    </>
  )
}
