import React from "react";
import { Paper, Typography, Button, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { WordpressExam, SphereEngineExam } from "./";
import { EXAM_CODE } from "constants/constants";
import { examsSessionWindow } from "./styles/styles";

export function ExaminationWindow() {
  const params = useParams();
  const styles = examsSessionWindow();

  return (
    <>
      {(() => {
        switch (params.examCode) {
          case EXAM_CODE.WCE:
          case EXAM_CODE.WCA:
          case EXAM_CODE.WWS:
          case EXAM_CODE.WES:
            return <WordpressExam />;
          /** Wordpress cerified developer doesn't need instance */
          case EXAM_CODE.AIEE:
          case EXAM_CODE.WCD:
          case EXAM_CODE.JSCS:
          case EXAM_CODE.PCS:
          case EXAM_CODE.PCA:
          case EXAM_CODE.CCA:
          case EXAM_CODE.C_SHARP:
          case EXAM_CODE.HTML_CSS:
          case EXAM_CODE.T_SQL:
          case EXAM_CODE.SQLCS:
          case EXAM_CODE.SQLCA:
          case EXAM_CODE.ASPNET:
          case EXAM_CODE.PHPCS:
          case EXAM_CODE.JCS:
          case EXAM_CODE.JCA:
          case EXAM_CODE.CPCS:
          case EXAM_CODE.CPPCS:
          case EXAM_CODE.AIEE:
            return <SphereEngineExam/>;
          default:
            return (
              <Grid container className={styles.noCodeContainer}>
                <Paper className={styles.noCodeContainerMsg}>
                  <Typography variant="h6" color="secondary" align="center">
                    Looks like we don't have an exam with such a code.
                  </Typography>
                  <Grid item xs={12} className={styles.noCodeButtonContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      href="/user/exams-selection">
                      Back to Dashboard
                    </Button>
                  </Grid>
                </Paper>
              </Grid>
            );
        }
      })()}
    </>
  );
}
