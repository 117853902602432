import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Grid, Typography, Paper, Box } from "@mui/material";
import { BackdropLoad, CardMessage, PrevNextSection } from "components";
import { LINKS } from "constants/links";
import { cleanStateToDefault, initExamSession } from "store/slices/examsSession.js";
import { addPaymentPromise } from "store/slices/payment.js";
import LanguageIcon from "@mui/icons-material/Language";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "80px",
    paddingBottom: "80px",
    justifyContent: "center"
  },
  examInfoContainer: {
    padding: theme.spacing(5),
    width: "80%",
    [theme.breakpoints.up("sm")]: {
      width: "70%"
    },
    [theme.breakpoints.up("md")]: {
      width: "50%"
    }
  },
  examInfoTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: "bold"
  },
  DividerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "50%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  ContentContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  ExamDetail: {
    display: "flex",
    padding: theme.spacing(1)
  },
  Icon: {
    marginRight: "5px"
  },
  Details: {
    color: theme.palette.text.primary
  },
  DetailsContent: {
    color: theme.palette.secondary.main
  },
  importantMessage: {
    marginTop: theme.spacing(4)
  }
}));

const ExamsInfo = () => {
  const {
    root,
    examInfoContainer,
    examInfoTitle,
    DividerContainer,
    DividerBar,
    ContentContainer,
    ExamDetail,
    Icon,
    Details,
    DetailsContent,
    importantMessage
  } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSessionInitiating, setIsSessionInitiating] = useState(false);

  const {hasErrors: sessionErrors} = useSelector(
    (state) => state
  ).examsSessionSlice;
  const { exam } = useSelector((state) => state).examsSlice;
  const { paymentMethod } = useSelector((state) => state).paymentSlice;
  const { group, testingGroupCode } = useSelector((state) => state).groupSlice;
  const [memoizeError, setMomoizeError] = useState(false);
  const examusDisabledByCode = useSelector(state => state.groupSlice?.examusDisabledByCode);

  const minutes = useMemo(() => {
    return Math.floor(exam?.sessionTimeoutSeconds / 60);
  }, [exam]);

  const handlePrev = useCallback(() => {
    switch (paymentMethod) {
      case "voucher":
        navigate(LINKS.examsVoucher);
        break;
      case "license":
        navigate(LINKS.examsPaymentMethod);
        break;
      default:
        break;
    }
  }, [paymentMethod]);

  const checkOnSite = () => {
    if (group.typeProctoring === "Onsite") {
      navigate(`${LINKS.examsProctorVerifi}`);
      return;
    }
    navigate(`${LINKS.examWindow}/${exam.code}`);
  };

  const handleNext = useCallback(() => {
    if (!memoizeError) {
      setIsSessionInitiating(true);

      dispatch(initExamSession({ examCode: exam.code, testingGroupCode })).then(
        ({data}) => {
          setIsSessionInitiating(false);
          if (!data?.error) {
            return checkOnSite();
          }
          setMomoizeError(true);
        }
      );

      return;
    }

    dispatch(addPaymentPromise()).then(({ error }) => {
      if (!error) checkOnSite();
    });
  }, [exam, memoizeError]); // eslint-disable-line

  if (!exam?.code) {
    return (
      <Navigate to={LINKS.examsSelection}/>
    )
  }

  if (sessionErrors) {
    return (
      <CardMessage
        messageColor="primary"
        message="You cannot join this group because you are not a student at this school"
        handleClick={() => {
          dispatch(cleanStateToDefault())
          navigate("/")
        }}
        buttonColor="primary"
        buttonVariant="contained"
        buttonSize="medium"
        buttonPosition="center"
      />
    )
  }

  if (isSessionInitiating) {
    return <BackdropLoad open={true}/>;
  }

  return (
    <>
      <BackdropLoad open={isSessionInitiating}/>
      <Grid className={root} container justifyContent="center" alignItems="center">
        <Paper className={examInfoContainer}>
          <Typography
            className={examInfoTitle}
            align="center"
            variant="h5"
            color="primary">
            {exam?.displayName} {" exam"}
          </Typography>
          <Box className={DividerContainer}>
            <Divider className={DividerBar}/>
          </Box>
          <Grid container>
            <Grid className={ContentContainer} item xs={6}>
              {/* <Box className={ExamDetail}>
              <CodeIcon color="primary" className={Icon} />
              <Typography className={Details}>
                Exam Name:{' '}
                <span className={DetailsContent}>{exam?.displayName}</span>
              </Typography>
            </Box> */}
              <Box className={ExamDetail}>
                <LanguageIcon color="primary" className={Icon} />
                <Typography className={Details}>
                  Language:{" "}
                  <span className={DetailsContent}>{exam?.language}</span>
                </Typography>
              </Box>
              <Box className={ExamDetail}>
                <QueryBuilderIcon color="primary" className={Icon} />
                <Typography className={Details}>
                  Exam length:{" "}
                  <span className={DetailsContent}>{`${minutes} min`}</span>
                </Typography>
              </Box>
              <Box className={ExamDetail}>
                <FormatListNumberedIcon color="primary" className={Icon} />
                <Typography className={Details}>
                  Number of Questions:{" "}
                  <span className={DetailsContent}>{exam.tasksAmount}</span>
                </Typography>
              </Box>
              <Box className={ExamDetail}>
                <CheckCircleOutlineIcon color="primary" className={Icon} />
                <Typography className={Details}>
                  Passing score: <span className={DetailsContent}>{"75%"}</span>
                </Typography>
              </Box>
            </Grid>
            <Grid className={ContentContainer} item xs={6} />
          </Grid>
          {group.typeProctoring !== "Onsite" && !examusDisabledByCode && (
            <Typography
              className={importantMessage}
              color="error"
              variant="subtitle1"
              align="center">
              You are being redirected to <b>Examus Online Proctoring</b>{" "}
              website to complete your proctored online exam. Please click{" "}
              <b>Next</b> to continue.
            </Typography>
          )}
          <PrevNextSection
            handlePrev={handlePrev}
            handleNext={handleNext}
            NextText={"NEXT"}
            disabledNext={isSessionInitiating}
          />
        </Paper>
      </Grid>
    </>
  );
};

export default ExamsInfo;
