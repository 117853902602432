import apiCall from "../api/api";
import { ENDPOINT } from "../constants/endpoints";

function getById(id) {
    return apiCall({
        url: `${ENDPOINT.organization}/${id}`,
        method: "GET",
    });
}

function update(id, value) {
    return apiCall({
        url: `${ENDPOINT.organization}/${id}`,
        method: "PATCH",
        data: value,
    });
}

const OrganizationService = {
    getById,
    update
};

export default OrganizationService;